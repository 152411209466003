<template lang="pug">
  v-container
    base-snackbar(
      :errors.sync="stockCatPostErrors"
    )
    base-snackbar(
      :errors.sync="revenueSourceIndexGetErrors"
    )
    v-row
      v-col(cols="12")
        v-toolbar(
          color="primary"
          dark
          dense
          elevation="0"
        )
          span New Category
          v-spacer
          v-icon mdi-shape
      v-col(cols="12")
        v-text-field(
          label="Category ID"
          v-model="id"
          :disabled="editMode"
          :error-messages="stockCatPostErrors.id || []"
        )
      v-col(cols="12")
        v-autocomplete(
          label="Revenue Source"
          v-model="revenueSourceId"
          :items="revenueSourceIndexList"
          :loading="revenueSourceIndexGetting"
          :error-messages="stockCatPostErrors.revenue_source_id"
          item-value="id"
          item-text="code"
        )
          template(v-slot:item="{ item }")
            span.primary--text {{ item.code }}
            small.ml-2.blue-grey--text {{ item.description }}
      v-col(cols="12")
        v-text-field(
          label="Name"
          v-model="name"
          :error-messages="stockCatPostErrors.name || []"
        )
      v-col(
        cols="12"
        v-if="!editMode"
      )
        v-btn(
          color="primary"
          @click="storeStockCat"
          dark
          block
          :loading="stockCatPosting"
        )
          span Save
      v-col(
        cols="12"
        v-else
      )
        v-row
          v-col(cols="6")
            v-btn(
              color="yellow darken-2"
              dark
              block
              :loading="stockCategoryUpdatePutting"
              @click="update"
            )
              span Update
          v-col(cols="6")
            v-btn(
              color="error"
              dark
              block
              @click="cancelEdit"
            )
              span Cancel
</template>
<script>
import revenueSourceRepository from '@/repositories/revenue-source.repository'
import stockCatRepository from '@/repositories/stock-category.repository'
import { postVars, errorHandler, requestVars } from '@/libs/api-helper.extra'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import searchDelay from '@/libs/searchDelay.extra'

const storeDelay = searchDelay()

const [stockCatUpdateVars, stockCatUpdateVarNames] = requestVars({ identifier: 'stock-category-update', hasData: false, request: 'put' })
const [revenueSourceIndexVars, revenueSourceIndexVarNames] = requestVars({ identifier: 'revenue-source-index' })

const revenueSourceIndexHandler = new VueRequestHandler(null, revenueSourceIndexVarNames)
const stockCatUpdateHandler = new VueRequestHandler(null, stockCatUpdateVarNames)

const inputVars = () => ({
  id: null,
  name: null,
  revenueSourceId: null,
})

export default {
  name: 'CreateCustomerCategory',
  props: ['edit'],
  data () {
    return {
      ...inputVars(),
      ...postVars('stockCat'),
      ...revenueSourceIndexVars,
      ...stockCatUpdateVars,
    }
  },
  computed: {
    editMode () {
      return !this.$objectEmpty(this.edit)
    },
  },
  created () {
    this.getRevenueSources()
  },
  watch: {
    edit (data) {
      Object.keys(data)
        .forEach(key => {
          this[key.snakeToCamelCase()] = data[key]
        })
    },
  },
  methods: {
    getRevenueSources () {
      const handler = revenueSourceIndexHandler
      const repository = revenueSourceRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    storeStockCat () {
      if (this.stockCatPosting) return
      this.stockCatPosting = true
      this.stockCatPostErrors = []
      storeDelay(() => {
        const data = this.getInputData()
        stockCatRepository.store(data)
          .then(() => { this.reset() })
          .catch(e => { this.stockCatPostErrors = errorHandler(e) })
          .then(() => { this.stockCatPosting = false })
      })
    },
    update () {
      const handler = stockCatUpdateHandler
      const repository = stockCatRepository.update
      const { id, ...data } = this.getInputData()
      handler.setVue(this)
      handler.execute(repository, [id, data], this.cancelEdit)
    },
    reset () {
      const defaults = inputVars()
      Object.keys(defaults)
        .forEach(key => {
          this[key] = defaults[key]
        })
    },
    cancelEdit () {
      this.reset()
      this.$emit('update:edit', {})
    },
    getInputData () {
      return {
        id: this.id,
        name: this.name,
        revenue_source_id: this.revenueSourceId,
      }
    },
  },
}
</script>
